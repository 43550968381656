input {
  width: 100%;
  padding: 6px 12px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
}
select {
  width: 45%;
  padding: 6px 12px;
  margin: 8px 6px;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
  background-color: #fff;
}

.payment_container {
  display: flex;
  justify-content: space-between;
  margin: 24px;
  align-items: center;
}

.payment_heading {
  font-size: 16px;
  font-weight: bold;
}

.add_new_card {
  font-size: 12px;
  color: #696969;
}

.debit_card_container {
  width: 80%;
  background-color: #ffefef;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  padding: 24px;
  margin: auto;
  margin-bottom: 50px;
}

.form_top_view {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.add_cart_button_modal {
  border: none;
  border-radius: 10px;
  font-size: 12px;
  width: 100%;
  margin: 20px 0px;
  padding: 10px;
  background-color: #000;
  color: #fff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
}

.form_label {
  font-size: 12;
  color: #696969;
}

.form_bottom_view {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 24px;
}
