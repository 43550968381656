.bottom_nav {
  position: fixed;
  width: 100%;
  display: flex;
  justify-content: space-around;
  bottom: 0;
  right: 0;
  background-color: white;
  z-index: 2;
  height: 58px;
  border-top: 1px solid #ebebeb;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.bottom_nav_item {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  transition: all 0.3s ease;
}

.cart_length_container {
  background-color: #000;
  border-radius: 8px;
  padding: 0px 5px;
  position: absolute;
  top: 10px;
  margin-left: 24px;
}

.cart_length_text {
  color: #fff;
  font-size: 11px;
}
