body {
  margin: 0;
}

p {
  margin: 0;
  padding: 0;
}

.button {
  background-color: #1e40af;
  border: none;
  color: white;
  padding: 6px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 12px;
}
