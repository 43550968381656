.container {
  height: 80px;
  position: sticky;
  top: 0px;
  background-color: #fff;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
  padding: 12px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.featured_text {
  font-size: 24px;
  font-weight: 600;
  color: #3d3d3d;
}

.items_count {
  font-size: 14px;
  color: #808088;
}

.flex_container {
  display: flex;
}

.sort_container {
  display: flex;
  align-items: center;
}

.help_text {
  font-size: 14px;
  color: #808088;
  margin-left: 6px;
}

.filter_img {
  display: flex;
  margin-left: 12px;
  margin-right: 12px;
  align-items: center;
}

.buy_text {
  text-align: center;
  font-weight: bold;
}

.product_list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 12px;
  margin-bottom: 150px;
}

.link {
  text-decoration: none;
  position: fixed;
  bottom: 70px;
  width: 90%;
  border-radius: 6px;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);
  background-color: #000;
  color: #fff;
  padding: 10px;
}
